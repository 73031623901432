/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.css";
@import 'styles-variables';
@import '@alpes-controles/ui-lib';
@import 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap';
@import '~quill/dist/quill.snow.css';

html {
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: $primary-font, $secondary-font, "Helvetica Neue", sans-serif;
}
a {
    text-decoration: none!important;
}
a:hover {
    text-decoration: underline!important;
}



.info-tooltip {
  white-space: pre-line !important;
  font-size: 1em;
  max-width: 700px !important;
  color: #8E182D;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(assets/fonts/gotham/Gotham-Bold.otf) format("opentype"),
        url(assets/fonts/gotham/GothamBold.ttf) format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Gotham";
  src: local("GothamBook"),
  url(assets/fonts/gotham/Gotham-Book.otf) format("opentype"),
  url(assets/fonts/gotham/GothamBook.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Gotham";
  src: local("GothamLight"),
  url(assets/fonts/gotham/Gotham-Light.otf) format("opentype"),
  url(assets/fonts/gotham/GothamLight.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Gotham";
  src: local("GothamMedium"),
  url(assets/fonts/gotham/Gotham-Medium.otf) format("opentype"),
  url(assets/fonts/gotham/GothamMedium.ttf) format("truetype");
  font-weight: 500;
}

*{
  font-family: $primary-font, $secondary-font, "Helvetica Neue", sans-serif;
}

.verification-code .mat-mdc-radio-button.mat-accent {
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-ripple .mat-ripple-element {
        opacity: 0 !important; /*click effect color change*/
        background-color: $primary-color !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-inner-circle {
        background-color: $primary-color !important; /*inner circle color change*/
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    &.mat-radio-checked .mat-radio-outer-circle {
        border-color: $primary-color !important; /*outer ring color change*/
    }
}


.icon-btn, .cursor-pointer {
  cursor: pointer;

}

mat-icon.outlined {
  @extend .material-icons-outlined !optional;
}

.mat-mdc-paginator {
  background: transparent!important;
}

.green-button {
  background-color: #4CAF50 !important;
  color: white !important;
}





/*
.mat-card-content {
  .mat-spinner {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
  }
}
*/

app-model-picker {
    ac-search-field {
        display: block;
        margin-bottom: 10px;

        > .search-input {
            max-width: 100% !important;
        }
    }
}

label {
  margin-bottom: 0;
}

.dark-btn {
  --mdc-protected-button-container-color: #3c3c3b !important;
  color: white !important;
}

.green-btn {
  --mdc-protected-button-container-color: #4CAF50!important;
  color: white !important;
}