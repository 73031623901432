@use '@angular/material' as mat;
$toolbar-breakpoint: 600px;
$side-nav-width: 250px;
$link-hover-decoration: none;
$label-margin-bottom: 0;
$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
$success-colors: mat.define-palette(mat.$green-palette, 400);
$warning-colors: mat.define-palette(mat.$amber-palette, 400);

$primary-color: #b22728;
$dark-primary-color: #b42f27;
$light-primary-color: #fff8f8;
$orange-color: #eb5e2c;
$green-color: #75b842;
$red-color: #c95454;
$accord-commercial-red: #891633;
$blue-color: #609DD3;
$dark-blue-color: #3d6083;
$grey-color: #b2b2b1;
$dark-color: #3c3c3b;
$grey-background: #f2f2f2;
$white-background: #ffffff;

$primary-font: Montserrat;
$secondary-font: Roboto;

$default-font: Gotham, Roboto, "Helvetica Neue", sans-serif;

$mobile-bottom-toolbar-height: 60px;


// Extra small screen / phone
$width-xs: 0;
// Small screen / phone
$width-sm: 576px;
// Medium screen / tablet
$width-md: 768px;
$min-width-md: 769px;
// Large screen / desktop
$width-lg: 992px;
// Extra large screen / wide desktop
$width-xl: 1200px;



@media (max-width: $width-md) {
  .hide-mobile {
    display: none!important;
  }
}


@media (min-width: $min-width-md) {
  .hide-desktop {
    display: none!important;
  }
}
